<template>
  <div>
    <vs-popup class="sm:popup-w-70" :title="'Pilihan Vendor untuk ' + row.nama_item_pengadaan" :active="active" v-on:update:active="emitModalIsActive">
      <div class="mt-4">
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="flex flex-wrap justify-between items-center mb-1">
              <div class="mb-4 md:mb-0 mr-4">
                <div class="flex">
                  <vs-button color="success" icon-pack="feather" icon="icon-plus" @click="addRow" class="py-2 px-3 mr-2">Add</vs-button>
                  <vs-button color="danger" icon-pack="feather" icon="icon-x" :disabled="vendors.length < 1" @click="clearRows" class="py-2 px-3">Clear</vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <vs-table :data="vendors" stripe class="fix-table-input-background">
          <template slot="thead">
            <vs-th class="whitespace-no-wrap">#</vs-th>
            <vs-th class="whitespace-no-wrap">Action</vs-th>
            <vs-th class="whitespace-no-wrap">Nama Vendor *</vs-th>
            <vs-th class="whitespace-no-wrap">Keterangan</vs-th>
            <vs-th class="whitespace-no-wrap">File Penawaran</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr v-for="(item, index) in data" :key="item.uuid" class="text-sm">
              <vs-td>{{ index + 1 }}</vs-td>
              <vs-td>
                <vs-button color="danger" class="py-2 px-3" type="filled" icon-pack="feather" icon="icon-x" size="small" @click="removeRow(item.uuid)"/>
              </vs-td>
              <vs-td>
                <vs-input class="w-full" v-model="item.nama_rekanan"/>
              </vs-td>
              <vs-td>
                <vs-input class="w-full" v-model="item.keterangan"/>
              </vs-td>
              <vs-td>
                <div class="flex items-center w-full">
                  <vs-input v-if="!item.fileTemp" class="w-full"  type="file" accept=".jpeg,.jpg,.png,.pdf" v-model="item.fileTemp" @change="item.file = $event.target.files[0]"/>
                  <div v-if="item.fileTemp" class="flex w-full">
                    <feather-icon class="cursor-pointer" icon="XIcon" svg-classes="h-5 w-5 text-danger" @click="item.fileTemp = null; item.file = null"/>
                    <span class="ml-1 w-full">{{ itemFilePenawaranName(item.fileTemp) }}</span>
                  </div>
                  <!--when edit show old file-->
                  <a :href="item.file_penawaran_url" target="_blank" v-if="item.file_penawaran_url && !item.fileTemp" class="ml-2">
                    <vs-chip color="primary"><vs-avatar icon-pack="feather" icon="icon-file"/><span>File</span></vs-chip>
                  </a>
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>

        <div class="vx-row float-right mt-5">
          <div class="vx-col w-full">
            <vs-button @click="done" type="border" class="mr-3">Selesai</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import _ from 'lodash'
import { v4 as uuid } from 'uuid'

export default {
  name: 'RekananChooser',
  props: ['active', 'rowUuid'],
  computed: {
    row () {
      const row = this.$store.getters['procurement/pengadaanAdd/getTabItemRowByUuid'](this.rowUuid)
      return row || {}
    }
  },
  watch: {
    rowUuid (newVal, oldVal) {
      if (newVal && !_.isEqual(newVal, oldVal)) {
        this.vendors = _.cloneDeep(this.row.vendors)
      }
    }
  },
  data () {
    return {
      vendors: []
    }
  },
  methods: {
    addRow () {
      const row = { uuid: uuid() }
      this.vendors.unshift(row)
    },

    removeRow (uuid) {
      const index = _.findIndex(this.vendors, item => item.uuid === uuid)
      this.vendors.splice(index, 1)
    },

    clearRows () {
      this.vendors = []
    },

    done () {
      this.emitModalIsActive(false)
    },

    itemFilePenawaranName (fileTemp) {
      return /[^\\]*$/.exec(fileTemp)[0]
    },

    emitSelected () {
      this.$emit('selected', this.vendors)
    },

    emitModalIsActive (isActive) {
      if (!isActive) this.emitSelected()
      this.$emit('update:active', isActive)
    }
  }
}
</script>
